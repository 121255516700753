import { Component, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

    subscription: Subscription;


    constructor(private router: Router,
      public translate: TranslateService) {
  
      // this language will be used as a fallback when a translation isn't found in the current language
      this.translate.setDefaultLang('pt-BR');
  
      // the lang to use, if the lang isn't available, it will use the current loader to get them
      this.translate.use('pt-BR');
    }

    ngOnInit() {
        this.subscription = this.router.events
          .pipe(
            filter(event => event instanceof NavigationEnd)
          )
          .subscribe(() => window.scrollTo(0, 0));
    
        console.log(this.translate.currentLang);
      }

    
    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }


}
