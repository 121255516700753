import {Injectable} from '@angular/core';

import {UserB} from './user-b';
import {HttpClient} from '@angular/common/http';
import {StorageUtils} from '../../../utils/storage-utils';
import {RestService} from '../../../utils/rest-service';
import {JsonContainer} from '../../../utils/json-container';
import { AddressInfo } from 'app/pages/msk-core/address/address-info';
import { PhotoUpload } from 'app/pages/msk-core/photo/photo-upload';


@Injectable()
export class UserBExtService extends RestService {

  constructor(private client: HttpClient, private storageUtils: StorageUtils) {
    super(client, 'userBackofficeExt', storageUtils);
  }

  authenticate(userB: UserB, callback: (result: JsonContainer, user: UserB) => void) {
    this.executePost('authenticateMobile', userB, callback)
  }

  saveUserBc(user: UserB, callback: (result: JsonContainer, user: UserB) => void) {
    this.executePost('saveUserc', user, callback)
  }

  returnAddress(cep: string, callback: (result: JsonContainer, addressInfo) => void) {
    this.executeGet('locateAddress/' + cep, callback)
  }

  saveManagerSignature(photoUpload: PhotoUpload, callback: (result: JsonContainer) => void) {
    this.executePost('saveManagerSignature', photoUpload, callback)
  }

  managerSignContract(fields: {}, callback: (result: JsonContainer) => void) {
    this.executePost('managerSignContract', fields, callback)
  }

}