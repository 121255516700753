export class Constants {

  public static readonly DATE_FMT = 'dd/MM/yyyy';
  public static readonly DATE_TIME_FMT = `${Constants.DATE_FMT} hh:mm:ss`;

  // // Local
  //  public static get SERVICE_URL(): string {
  //   return 'http://localhost:8080';
  //  };




  // Staging
  // public static get SERVICE_URL(): string {
  //     return 'http://api.arktecrh.orangestaging.com';
  // };

  public static get LOCATION_URL(): string {
    return '.orangestaging.com'
  }




  // Production
  public static get SERVICE_URL(): string {
      return 'https://arktecrh.startup-kit.net';
  };

  // public static get LOCATION_URL(): string {
  //   return '.orangebox.technology'
  // }






  public static get SERVICE_PROJETC(): string {
    return '/';
  };
}
