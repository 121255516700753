import { Routes, RouterModule } from '@angular/router';

// Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'page',
    loadChildren: () => import('../../page/page.module').then(m => m.PageModule)
  },
  {
    path: 'page',
    loadChildren: () => import('../../pages/msk-company/msk-company.module').then(m => m.MSKCompanyModule)
  },
  {
    path: 'page',
    loadChildren: () => import('../../pages/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'page',
    loadChildren: () => import('../../pages/msk-admin/msk-admin.module').then(m => m.MSKAdminModule)
  },
  {
    path: 'page',
    loadChildren: () => import('../../pages/msk-catalogue/msk-catalogue.module').then(m => m.MskCatalogueModule)
  },
  {
    path: 'page',
    loadChildren: () => import('../../pages/msk-user/msk-user.module').then(m => m.MskUserModule)
  },
  {
    path: 'page',
    loadChildren: () => import('../../pages/msk-crm/msk-crm.module').then(m => m.MskCrmModule)
  },
  {
    path: 'page',
    loadChildren: () => import('../../pages/regreq/reg-req.module').then(m => m.RegReqModule)
  },
  {
    path: 'page',
    loadChildren: () => import('../../pages/contract-register/contract-register-module').then(m => m.ContractRegisterModule)
  }
];
